.banner1 {
  width: 285px;
  background-color: #fff;
  flex-shrink: 0;
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__content {
    padding: 50px 40px 25px 40px;
  }
  &__title {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 20px;
    display: block;
    color: #071444;
    padding-bottom: 30px;
  }
  &__text {
    color: #071444;
    padding-bottom: 30px;
    display: block;
  }
  &__link {
    text-transform: uppercase;
  }
}
.banner2 {
  width: 100%;
  &__link {
    display: block;
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

@media all and(max-width: 992px) {
  .banner1 {
    width: 220px;
    &__content {
      padding: 25px 20px;
    }
  }
}
@media all and(max-width: 576px) {
  .banner1 {
    width: 100%;
    margin-top: 60px;
    &__content {
      padding: 25px 20px;
    }
  }
}