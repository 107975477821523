.personal-number {
  padding: 0 20px 0 0;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #c4c6cc;
  }
  &__container {
    position: relative;
  }
  &__item {
    font-size: 21px;
    padding-left: 50px;
    position: relative;
    z-index: 1;
    height: 41px;
    display: block;
    padding-top: 7px;
    &_1 {
      color: #000;
    }
    &_2 {
      color: #bcbdc1;
    }
    &_3 {
      color: #79b74d;
    }
  }
  &__button {
    height: 40px;
    width: 40px;
    border: 0;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    outline: none;
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.3s;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url('../img/icons/arrow-right.svg') center center no-repeat;
      background-size: 14px 12px;
      transition: transform 0.3s;
      transform: rotate(-90deg);
      opacity: 0.6;
    }
    &:hover {
      background-color: #ebebeb;
    }
    &_active {
      &:before {
        transform: rotate(0);
      }
    }
  }
  &__dropdown-container {
    display: none;
  }
}