.desktop-nav {
  &__list {
    padding: 44px 0 0 0;
    margin: 0;
    list-style: none;
  }
  &__item {
    padding-bottom: 5px;
    &:last-child {
      padding: 0;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 0 0 0 50px;
    color: #606465;
    height: 40px;
    position: relative;
    transition: 0.3s;
    opacity: 0.8;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background-size: 25px 25px !important;
    }
    &_1 {
      &:before {
        background: url('../img/icons/ico-pay.svg') no-repeat center center;
      }
    }
    &_2 {
      &:before {
        background: url('../img/icons/ico-support.svg') no-repeat center center;
      }
    }
    &_3 {
      &:before {
        background: url('../img/icons/ico-gear.svg') no-repeat center center;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}